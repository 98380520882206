import React, { useState } from "react";
import "./App.css";
import Memo from "./ViewMemo";
import { supabase } from "./supabase";
import { INPUT_LABEL_DETAIL, INPUT_LABEL_TITLE, STR_BLANK } from "./constants";

function App() {
  // ログイン処理
  const login = async () => {
    var password = "supabase1120" as string;
    var email = "assist676.deech@gmail.com" as string;

    try {
      const { error } = await supabase.auth.signInWithPassword({
        password,
        email,
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("email:" + email + " , " + "pass:" + password);
    }
  };

  // ログアウトの処理を追加
  const doLogout = async () => {
    // supabaseに用意されているログアウトの関数
    console.log("ログアウト");
    const { error } = await supabase.auth.signOut();
  };

  return (
    <>
      <div className="sts-btns">
        <button className="sts-btn btn-square-shadow" onClick={login}>
          ON
        </button>
        <button className="sts-btn btn-square-shadow" onClick={doLogout}>
          OFF
        </button>
      </div>
      <Memo />
    </>
  );
}

export default App;
