import { FC, useState } from "react";
import {
  INPUT_LABEL_DETAIL,
  INPUT_LABEL_LIMIT,
  INPUT_LABEL_TITLE,
  MODE_VIEW,
  STR_BLANK,
} from "./constants";
import { Todo } from "./type";
import { updateTodo } from "./supabaseUtils";

const EditMemo: FC<{ setMode: any; editTodo: Todo }> = ({
  setMode,
  editTodo,
}) => {
  // 入力情報
  const [date, setDate] = useState<string>(editTodo.regDate); // 期限
  const [title, setTitle] = useState<string>(editTodo.title); // タイトル
  const [detail, setDetail] = useState<string>(editTodo.detail); // 詳細

  // データ更新
  const update = async (todo: Todo) => {
    if (
      editTodo.regDate == date &&
      editTodo.title == title &&
      editTodo.detail == detail
    ) {
      alert("更新する値がありません。");
      return;
    }

    updateTodo(todo);
    alert("更新が完了しました。");
    setMode(MODE_VIEW);
  };

  return (
    <>
      <div className="reg-panel border-setUp">
        <div>
          <div className="lbl-div">{INPUT_LABEL_LIMIT}</div>
          <input
            id="input-date"
            type="date"
            defaultValue={editTodo.regDate}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          ></input>
        </div>
        <div>
          <div className="lbl-div">{INPUT_LABEL_TITLE}</div>
          <input
            id="input-title"
            type="text"
            defaultValue={editTodo.title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          ></input>
        </div>
        <div>
          <div className="lbl-div">{INPUT_LABEL_DETAIL}</div>
          <textarea
            id="input-detail"
            rows={4}
            defaultValue={editTodo.detail}
            onChange={(e) => setDetail(e.target.value)}
          ></textarea>
        </div>
        <div>
          <button
            className="upd-btn btn-square-shadow"
            onClick={() =>
              update({
                id: editTodo.id,
                title: title,
                detail: detail,
                regDate: date,
              } as Todo)
            }
          >
            Update
          </button>
        </div>
      </div>
      <div>
        <button
          className="rtn-btn btn-square-shadow"
          onClick={() => setMode(MODE_VIEW)}
        >
          Top
        </button>
      </div>
    </>
  );
};

export default EditMemo;
