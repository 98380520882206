import { useEffect, useState } from "react";
import { deleteTodo, getAllTodo, insertTodo } from "./supabaseUtils";
import { Todo, editProps } from "./type";
import {
  INPUT_LABEL_DETAIL,
  INPUT_LABEL_LIMIT,
  INPUT_LABEL_TITLE,
  MODE_EDIT,
  MODE_VIEW,
  STR_BLANK,
  STR_HYP,
  STR_SRA,
  UI_LABEL_EDIT,
  UI_LABEL_REGIST,
  getYYYYmmddDay,
} from "./constants";
import EditMemo from "./EditMemo";
import Linkify from "linkify-react";

const Memo = () => {
  // データプール
  const [allTodo, setTodo] = useState<Todo[]>([]); // Todoデータ

  // 画面ステータス
  const [mode, setMode] = useState<string>(MODE_VIEW);

  // 編集対象ID
  const [editId, setEditId] = useState<string>(STR_BLANK);

  // 入力情報
  const [date, setDate] = useState<string>(getYYYYmmddDay); // 期限
  const [title, setTitle] = useState<string>(STR_BLANK); // タイトル
  const [detail, setDetail] = useState<string>(STR_BLANK); // 詳細

  const getDBTodo = async () => {
    const dbTodo = await getAllTodo();
    setTodo(
      dbTodo?.map((data: Todo) => {
        return data;
      })
    );
  };

  // データ取得
  useEffect(() => {
    getDBTodo();
  }, [allTodo]);

  // データ登録
  const registTodo = async () => {
    if (date == STR_BLANK || title == STR_BLANK) {
      alert("期限またはタイトルが入力されていません。");
      return;
    }

    const inputDate = document.getElementById("input-date") as HTMLInputElement;
    inputDate.value = getYYYYmmddDay(); //テキストボックスを空にする

    const inputTitle = document.getElementById(
      "input-title"
    ) as HTMLInputElement;
    inputTitle.value = STR_BLANK; //テキストボックスを空にする

    const inputDetail = document.getElementById(
      "input-detail"
    ) as HTMLInputElement;
    inputDetail.value = STR_BLANK; //テキストボックスを空にする
    inputTitle.focus(); //テキストボックスにフォーカスを当てる

    insertTodo({
      // 登録
      title: title,
      detail: detail,
      regDate: date,
    } as Todo);

    setDate(getYYYYmmddDay); //現在日付でリセット
    setTitle(STR_BLANK);
    setDetail(STR_BLANK);

    alert("登録が完了しました。");

    getDBTodo(); // 更新後データ取得
  };

  // データ削除
  const removeTodo = async (todoId: string) => {
    let flg = window.confirm("削除してもよろしいでしょうか。");
    if (!flg) {
      return;
    }

    deleteTodo(todoId);
    getDBTodo(); // 更新後データ取得
  };

  const getEditTodo = (id: string) => {
    const editTodo = allTodo.filter((todo) => {
      return todo.id == id;
    });
    return editTodo[0] as Todo;
  };

  const changeMode = (editId: string) => {
    setMode(MODE_EDIT);
    setEditId(editId);
  };

  const options = {
    truncate: 20,
  };

  return (
    <div className="base-panel">
      {mode == MODE_EDIT ? (
        // 編集画面
        <>
          <EditMemo setMode={setMode} editTodo={getEditTodo(editId)} />
        </>
      ) : (
        // 表示画面
        <>
          <div className="reg-panel border-setUp">
            <div>
              <div className="lbl-div">{INPUT_LABEL_LIMIT}</div>
              <input
                id="input-date"
                type="date"
                defaultValue={date} // yyyy-mm-dd形式
                onChange={(e) => {
                  setDate(e.target.value);
                }}
              ></input>
            </div>
            <div>
              <div className="lbl-div">{INPUT_LABEL_TITLE}</div>
              <input
                id="input-title"
                type="text"
                onChange={(e) => setTitle(e.target.value)}
              ></input>
            </div>
            <div>
              <div className="lbl-div">{INPUT_LABEL_DETAIL}</div>
              <textarea
                id="input-detail"
                rows={4}
                onChange={(e) => setDetail(e.target.value)}
              ></textarea>
            </div>
            <div>
              <button
                className="reg-btn btn-square-shadow"
                type="button"
                onClick={registTodo}
              >
                Add
              </button>
            </div>
          </div>

          <ul>
            {allTodo.map((todo) => (
              <li key={todo.id} className="border-setUp">
                <div className="content-div">
                  {todo.regDate.replaceAll(STR_HYP, STR_SRA)} まで
                </div>
                <div className="content-div">
                  {todo.detail == STR_BLANK ? (
                    todo.title
                  ) : (
                    <details>
                      <summary>{todo.title}</summary>
                      {todo.detail}
                    </details>
                  )}
                </div>
                <div className="btns">
                  <button
                    className="edit-btn btn-square-shadow"
                    onClick={() => changeMode(todo.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="del-btn btn-square-shadow"
                    onClick={() => removeTodo(todo.id)}
                  >
                    Del
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Memo;
