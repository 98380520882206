// タイトルラベル定数
export const UI_LABEL_REGIST = "登録";
export const UI_LABEL_EDIT = "編集";
export const INPUT_LABEL_LIMIT = "期限";
export const INPUT_LABEL_TITLE = "タイトル";
export const INPUT_LABEL_DETAIL = "詳細";

// 文字列定数
export const STR_BLANK: string = "";
export const STR_ASTR: string = "*";
export const STR_SRA: string = "/";
export const STR_HYP: string = "-";

// 画面状態定数
export const MODE_VIEW: string = "0";
export const MODE_EDIT: string = "1";

// テーブル名定数
export const TBL_NAME_TODO: string = "todo";

// 現在日付取得(yyyy-mm-dd)
export const getYYYYmmddDay = () => {
  return new Date().toLocaleDateString("sv-SE") as string;
};
