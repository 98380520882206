import { STR_ASTR, TBL_NAME_TODO } from "./constants";
import { supabase } from "./supabase";
import { Todo } from "./type";

export const getAllTodo = async () => {
  const todos = await supabase.from(TBL_NAME_TODO).select(STR_ASTR);
  return todos.data as [];
};

export const insertTodo = async (todo: Todo) => {
  const { error } = await supabase.from(TBL_NAME_TODO).insert({
    title: todo.title,
    detail: todo.detail,
    regDate: todo.regDate,
  });

  if (error) {
    alert(error.message);
  }
};

export const deleteTodo = async (id: string) => {
  const { error } = await supabase.from(TBL_NAME_TODO).delete().eq("id", id);

  if (error) {
    alert(error.message);
  }
};

export const updateTodo = async (todo: Todo) => {
  const { error } = await supabase
    .from(TBL_NAME_TODO)
    .update({ title: todo.title, detail: todo.detail, regDate: todo.regDate })
    .eq("id", todo.id);

  if (error) {
    alert(error.message);
  }
};
